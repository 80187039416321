<section class="card p-3">
  <div class="card-block cardCont">
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="lastId === false" class="row">
          <div class="col-12 col-md-8 push-md-2">
            <div class="card px-2 py-2">
              <div class="card-content">
                <mat-icon class="alert-icon">
                  warning
                </mat-icon>
                <span class="alert-text">
                  Es tu primer e-recibo. Indica el número desde el cual deseas iniciar el consecutivo.
                </span>
              </div>
             </div>
          </div>
        </div>
        <div *ngIf="plan && (rLimit - (+rCount) < 5 && plan.receiptslimit - (+rCount) > 0)" class="row">
          <div class="col-12 col-md-8 push-md-2">
            <div class="card px-3 py-5">
              <div class="card-content">
                <mat-icon class="alert-icon">
                  warning
                </mat-icon>
                <span class="alert-text">
                  Solo quedan {{ rLimit - (+rCount) }} e-Recibo{{ rLimit - (+rCount) > 1 ? 's' : '' }} disponibles para emitir este mes.¿Necesitas ampliar?<br>Escríbenos a <a href="mailto:soporte@e-recibos.com">soporte@e-recibos.com</a>.
                </span>
              </div>
             </div>
          </div>
        </div>

        <div *ngIf="plan && (rLimit - (+rCount) <= 0)" class="row">
          <div class="col-12 col-md-8 push-md-2">
            <div class="card px-3 py-5">
              <mat-icon class="alert-icon">
                warning
              </mat-icon>
              <span class="alert-text">
                Se ha alcanzado el máximo de e-Recibos en el mes. ¿Necesitas ampliar?<br>Escríbenos a <a href="mailto:soporte@e-recibos.com">soporte@e-recibos.com</a>.
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8 push-md-2">
            <h2 class="receipt-title float-right">Recibo # <input *ngIf="lastId === false" class="" placeholder="1" matInput [(ngModel)]="initialCount"> <span *ngIf="lastId">{{+lastId + 1}}</span>
            </h2>
          </div>
        </div>
        <form id="form-receipt" class="form-receipt disable" [formGroup]="myForm" (ngSubmit)="register(myForm.value, myForm.valid)">
          <div class="row">
            <div class="col-12 col-md-8 push-md-2">
              <mat-form-field class="full-width">
                <mat-label style="display: inline-block;">{{('Client' | translate | uppercase)}}</mat-label>
                <input (input)="selectClient()" (click)="selectClient()" matInput formControlName="client">
                <mat-error class="alertBox" *ngIf="!myForm.controls['clientid'].valid">{{'* '+('Required Field' | translate)}}</mat-error>  
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 push-md-2 dollar-sign">
              <mat-form-field class="full-width">
                <input mask-money matInput id="amount" type="tel" class="mount-input input-size" formControlName="vouchertotal" autocomplete="off"/>
                <mat-error class="alertBox" *ngIf="!myForm.controls['vouchertotal'].valid">{{'* '+('Required Field' | translate)}}</mat-error>  
              </mat-form-field>
              <div *ngIf="country" class="ccode">{{ country.ccode }}</div>
            </div>
            <div class="col-md-4 push-md-2">
              <mat-form-field (click)="selectPaymentConcept()" (input)="selectPaymentConcept()" class="full-width">
                <mat-label>{{('Payment Concept' | translate | uppercase)}}</mat-label>
                <input class="input-size" matInput formControlName="paymentconcept">
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="visibility: hidden; height: 10px;">
            <div class="col-md-4 push-md-2">
              <mat-form-field class="full-width">
                <mat-label>{{'Deposit' | translate | uppercase}}</mat-label>
                <input matInput id="deposit" type="text" class="form-control input-size" formControlName="voucherdeposit" autocomplete="off"/>  
              </mat-form-field>
            </div>
            <div class="col-md-4 push-md-2">
              <mat-form-field class="full-width">
                <mat-label>{{'Pending Balance' | translate | uppercase}}</mat-label>
                <input matInput id="residue" type="text" class="form-control input-size" formControlName="voucherresidue"/>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 push-md-2">
              <mat-label style="display: block; width:100%;">{{('Payment Type' | translate | uppercase)}}</mat-label>
              <mat-button-toggle-group formControlName="paymenttypeid" (change)="onPaymentTypeChange($event.value)" class="payment-types-icons" appearance="legacy" name="fontStyle" aria-label="Font Style">
                <mat-button-toggle value="1" class="green-light">
                  <mat-icon>
                    payments
                  </mat-icon>
                  <div class="title">Efectivo</div>
                </mat-button-toggle>
                <mat-button-toggle value="3" class="green-dark">
                  <mat-icon>
                    money
                  </mat-icon>
                  <div class="title">Cheque</div>
                </mat-button-toggle>
                <mat-button-toggle value="2" class="blue-dark">
                  <mat-icon>
                    credit_card
                  </mat-icon>
                  <div class="title">T/C</div>
                </mat-button-toggle>
                <mat-button-toggle value="4" class="blue">
                  <mat-icon>
                    account_balance
                  </mat-icon>
                  <div class="title">ACH</div>
                </mat-button-toggle>
                <mat-button-toggle value="0" class="blue-light">
                  <mat-icon>
                      more_horiz
                  </mat-icon>
                  <div class="title crop-text">
                    {{ otherPaymentType ? otherPaymentType : 'Otro' }}
                  </div>
                </mat-button-toggle>
              </mat-button-toggle-group>
              <ul class="dropdown-menu payments" ngClass="{{ myForm.controls['paymenttypeid'].value == '0' ? 'visible' : '' }}" aria-labelledby="" role="menu">
                <ng-container *ngFor="let otherPaymentType of paymenttype_array; let i= index">
                  <a *ngIf="otherPaymentType.editable == true" class="dropdown-item" href="javascript:void(0)" (click)="selectOtherPaymentType(i)">
                    {{  otherPaymentType.paymenttypename }}
                  </a>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="row" *ngIf="myForm.controls['paymenttypeid'].value == 3">
            <div class="col-md-8 push-md-2  payment-type-child">
              <div class="row">
                <div class="col-8">
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'Bank' | translate | uppercase}}</mat-label>
                    <input matInput (click)="selectBank()" (input)="selectBank()" formControlName="bank" [disabled]="selectBankDisabled" class="input-size">
                  </mat-form-field>
                </div>
                <div class="col-4">
                  <mat-form-field class="full-width">
                    <mat-label>{{'Check Number' | translate | uppercase}}</mat-label>
                    <input matInput type="tel" class="input-size" formControlName="voucherchecknumber" disabled/>
                    <mat-error class="alertBox" *ngIf="!myForm.controls['voucherchecknumber'].valid">{{'* '+('Required Field' | translate)}}</mat-error>                  
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 push-md-2">
              <mat-form-field class="full-width">
                <mat-label>{{'Observations' | translate | uppercase}}</mat-label>
                <textarea maxlength="170" matInput formControlName="voucherobservation" class="voucher-observations"></textarea>
              </mat-form-field>
            </div>
          </div>
          <br>
          <div *ngIf="!plan || (plan && (rLimit - (+rCount) > 0))" class="text-center">
            <button type="submit" class="btn btn-primary btn-block  blockcenter">
              {{'Register Receipt' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<div class="modal fade modal-size-medium" id="modalMsgConfirm" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Recibo Generado</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body resize-modal">
        <div class="form-panel">
          <div style="text-align: center;" class="mb-2">
            <button type="button" class="btn" style="padding: 10px 24px;" (click)="sendemail('true')" title="Descargar">Descargar PDF</button>
          </div>
          <div style="text-align: center;" class="mb-2">
            <button [hidden]="hidden_button_msj" type="button" class="btn" style="padding: 10px 24px;" (click)="ocultar()" title="Enviar Correos">Enviar Correo</button>
          </div>
          <div [hidden]="!hidden_button_msj" class="style-form mt-4">
            <form id="form-sendemail" [formGroup]="myForm" (ngSubmit)="sendfromform(myForm.value,myForm.valid)">
              <div class="form-group">
                <label class="">Correo: {{ email_data.client_email }}</label>
              </div> 
              <div class="form-group">
                <label class="">CC:</label>
                <div>
                  <input type="text" class="form-control" formControlName="voucheremailreply" pattern="^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}(,?[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4})*$"/>
                  <div class="alertBox" *ngIf="myForm.controls['voucheremailreply'].errors?.pattern">{{'* '+('Field no valid' | translate)+'. ' +('Example' | translate) +': rodolfo@gmail.com ' + ('or' | translate) + ' ' + 'rodolfo@gmail.com,roberto@gmail.com' }}</div>
                </div>
              </div>
              <div style="text-align: center;">
                <button mat-raised-button type="submit" class="btn" style="padding: 10px 24px;" title="Enviar Correos" [disabled]="myForm.controls['voucheremailreply'].errors?.pattern">Enviar Correo</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
