<section class="card">
  <div class="card-header no-border">
    <span class="cat__core__title">
      <strong>{{ 'COMPANIES' | translate }}</strong>
    </span>
  </div>
  <div class="card-block">
    <div class="row">
      <div class="col-lg-12">
        <div>
          <ngx-datatable id="table" #table [rows]='companies' class='material expandable' [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50"
            [scrollbarH]="false" [rowHeight]="50" [limit]="10" (page)="onPage($event)" [messages]="{emptyMessage: 'Cargando Datos',  totalMessage: 'Total'}">
            <ngx-datatable-row-detail [rowHeight]="90" #myDetailRow (toggle)="onDetailToggle($event)">
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div style="padding-left:40px; font-size:14px">
                  <div>{{ row.dbname }}</div>
                  <div>{{ row.planid }}</div>
                  <div>{{ row.cbstatus }}</div>
                  <div>{{ row.expirationdate }}</div>
                </div>
              </ng-template>
            </ngx-datatable-row-detail>
            <ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false" [cellClass]="'desktop-hidden'" [headerClass]="'desktop-hidden'">
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <span (click)="toggleExpandRow(row)" class="material-icons">
                  {{ !expanded ? 'chevron_right' : 'expand_more' }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'Nombre'" [sortable]="true" [prop]="'company'" [canAutoResize]="true" [flexGrow]="5" [headerClass]="''" [cellClass]="''">
              <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span>{{ value }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'DB'" [sortable]="true" [prop]="'dbname'" [canAutoResize]="true" [flexGrow]="3" [headerClass]="''" [cellClass]="''">
              <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span>{{ value }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Plan" [sortable]="true" [prop]="'planid'" [flexGrow]="3" [cellClass]="'mobile-hidden-600'" [headerClass]="'mobile-hidden-600'">
              <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">ID</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span>{{ value }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Estado" [sortable]="true" [prop]="'cbstatus'" [flexGrow]="4" [cellClass]="'mobile-hidden-700'" [headerClass]="'mobile-hidden-700'">
              <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span>{{value}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'Expiración'" [prop]="'expiratindate'" [sortable]="false" [flexGrow]="1" [cellClass]="'mobile-hidden'" [headerClass]="'mobile-hidden'">
              <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span>{{column.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span> {{value?'Activo':'Inactivo'}} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'Actualizado'" [sortable]="true" [prop]="'changedate'" [flexGrow]="2" [cellClass]="'mobile-hidden'" [headerClass]="'mobile-hidden'">
              <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span> {{ convertDate(value) }} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Operaciones" [sortable]="false" [flexGrow]="3" [headerClass]="''" [cellClass]="'custom-cell-icons'">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span></span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>
                  <button type="button" class="btn btn-icon btn-outline-primary btn-link" title="Editar Companye" (click)="openEditCompany(row)"><mat-icon>edit</mat-icon></button>
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
  <div class="chargebee">
    <button (click)="loadChargebee()" class="cb">Generar ChargeBee</button>
  </div>
</section>
